<template>
    <div class="address-page">
        <div class="title">
            取货点管理
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加取货点</el-button>
            <!-- <div style="display: flex;">
               <el-input size='medium' v-model="search" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div> -->
        </div>

        <div class="table-container">
            <el-table border :data="addressList">
                <el-table-column  align="center" label="学校" >
                    <template slot-scope="scope">
                        {{scope.row.school_info? scope.row.school_info.school_name : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="pickup_points" align="center" label="地址">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="更新时间" align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span>
                         <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                         </span>
                         <span>
                             <el-popconfirm
                                confirmButtonText='好的'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteAddress(scope.row.pickup_points_id)"
                                title="确定删除该取货点吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getAddressList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}取货点`" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="addAddressDialog">
            <el-form :model="addAddressData" :rules="rules" ref="addAddressForm" label-width="70px">
                <el-form-item label="学校" >
                    <el-select v-model="addAddressData.schoolId" size="medium" placeholder="请选择">
                        <el-option v-for="item in schoolList" :key="item.school_id" :label="item.school_name" :value="item.school_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地址" prop="name">
                    <el-input size='medium' v-model="addAddressData.pickupPoints"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addAddressDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveAddress('addAddressForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Address',
    data () {
        return {
            // 验证规则
            rules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'change' }
                ],
                schoolId: [
                    { required: true, message: '请选择学校', trigger: 'change' }
                ]
            },

            schoolList: [],
            // 取货点列表
            addressList: [],
            page: 1,
            size: 10,
            total: 0,
            search: '',

            // 新增/编辑数据
            addAddressData: {
                schoolId: '',
                pickupPoints: ''
            },
            addAddressDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getSchoolList()
        this.getAddressList()
    },
    methods: {
        // 获取学校列表
        getSchoolList () {
            this.axios.post('/school/list', {
                page: 1,
                size: 100
            }).then((res) => {
                this.schoolList = res.data.list
            })
        },
        // 获取取货点列表
        getAddressList () {
            this.axios.post('/school/pickup/list', {
                page: this.page,
                size: this.size,
                search: this.search
            }).then((res) => {
                this.addressList = res.data.list.map(item => {
                    return item
                })
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getAddressList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param addressData 为编辑时，取货点数据
         */
        openAddDialog (isEdit, addressData) {
            this.isEdit = isEdit
            this.addAddressDialog = true
            if (isEdit) {
                this.addAddressData = {
                    pickupPointsId: addressData.pickup_points_id,
                    schoolId: addressData.school_id,
                    pickupPoints: addressData.pickup_points
                }
            } else {
                this.addAddressData = {
                    pickupPointsId: '',
                    schoolId: '',
                    pickupPoints: ''
                }

                this.$nextTick(() => {
                    this.$refs.addAddressForm.clearValidate()
                })
            }

            console.log(addressData)
        },

        /**
         * 删除取货点
         * @param editAddressId 删除ID
         */
        deleteAddress (pickupPointsId) {
            this.axios.post('/school/pickup/delete', {
                pickup_points_id: pickupPointsId
            }).then((res) => {
                if (res) {
                    this.getAddressList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存取货点数据
         * @param formName 表单名称
         */
        sureSaveAddress (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/school/pickup/update' : '/school/pickup/add'
                    const { pickupPointsId, schoolId, pickupPoints } = { ...this.addAddressData }
                    this.axios.post(requestUrl, {
                        pickup_points_id: pickupPointsId,
                        school_id: schoolId,
                        pickup_points: pickupPoints
                    }).then((res) => {
                        console.log(res)
                        this.addAddressDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}取货点`)
                        this.getAddressList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .address-page{
        .title{
            padding: 14px;
            // margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
